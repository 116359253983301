.animated {
  animation-duration: 150ms;
  animation-fill-mode: backwards;
  transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
  from {
    opacity: 0;
    /* transform: perspective(500px) translate3d(0, 0, -50px); */
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.intro {
  animation: intro 150ms ease-out;
}

/** enterRight */
@keyframes enterRight {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterRight {
  animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterLeft {
  animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(20%, 0, 0);
  }
}

.exitRight {
  animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(-20%, 0, 0);
  }
}

.exitLeft {
  animation-name: exitLeft;
}
